<div class="card">
  <h6 class="card-header">
    <cds-icon shape="contract" size="md"></cds-icon>
    <ng-container *ngIf="extern; else intern"
      >&nbsp;<a [routerLink]="['/contracts']">Verträge</a></ng-container
    >
    <ng-template #intern>&nbsp;Verträge</ng-template>
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h6>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <app-contracts-list-grid
        [contracts]="contracts"
        [loading]="loading"
        [hiddenFactory]="hiddenFactory"
      ></app-contracts-list-grid>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
