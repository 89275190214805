import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { selectProfil } from '../../users/+profil/profil.selectors';

import { selectCombinedContracts } from '../+store/contract.selectors';
import { ContractActions } from '../+store/contract.actions';

@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.css'],
})
export class ContractsListComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedContracts$ = this.store.select(selectCombinedContracts);

  constructor(
    private store: Store,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Verträge' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(ContractActions.loadContracts());
      }
    });
  }
}
