<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedContract$ | async as combinedContract">
      <app-contracts-detail-item
        [contract]="combinedContract.contract"
        [loading]="combinedContract.loading"
        [error]="combinedContract.error"
      ></app-contracts-detail-item>
    </div>
  </div>
</div>
